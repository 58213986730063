/* Global styles */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
body {
  font-family: "Poppins", sans-serif;
}
h1 {
  font-size: 2.6rem;
}
li,
button,
label,
input,
p {
  font-size: 1.5rem;
}
h1 {
  font-size: 4.8rem;
}
h2 {
  font-size: 3rem;
  font-family: "Lobster", sans-serif;
  font-weight: 400;
  font-style: normal;
}
h3 {
  font-size: 3rem;
  font-weight: normal;
}
h4,
h5 {
  font-size: 2rem;
}
a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}
.error-msg {
  color: red;
  font-size: 1rem;
}
.btn {
  padding: 1rem 2rem;
  background: black;
  border: none;
  border-radius: 10px;
  color: white;
  font-size: 1.25rem;
  cursor: pointer;
  transition: background 0.5s ease-in-out;
  animation: cloudAnimation 2s forwards;
}
.btn:hover {
  background: white;
  color: black;
}
.loading-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}
/* Nav section with hero */

.main-head {
  background-color: #131c27;
  color: white;
  position: sticky;
  top: 0px;
  z-index: 3;
}
nav {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: auto;
  padding: 2rem;
  min-height: 10vh;
  align-items: center;
}
nav ul {
  display: flex;
  flex: 1 1 40rem;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}
nav ul li a:hover {
  text-decoration: none;
  color: white;
}
#logo {
  flex: 2 1 40rem;
  font-family: "Pattaya", sans-serif;
  font-weight: 400;
}
#logo a:hover {
  text-decoration: none;
  color: white;
}
.custom-modal {
  margin: 0 auto;
}
.top-navbar {
  z-index: 99;
}
.nav-bar {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.nav-bar .nav-link {
  color: white;
  padding: 1rem;
  font-family: "Lobster", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2.5rem;
}
.nav-bar .nav-link:hover {
  transition: 0.5s ease-in-out;
  /* animation: cloudAnimation 2s forwards; */
  transform: scale(1.3);
}
.user-navbar {
  position: absolute;
  top: 10%;
  right: 10%;
  transform: translate(10%, -50%);
}
.logo-navbar {
  position: absolute;
  top: 10%;
  left: 5%;
  transform: translate(10%, -50%);
}
.logo-navbar .logo-container {
  height: 5rem;
  width: 10rem;
}
.user-navbar .nav-link {
  color: white;
  font-family: "Lobster", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2.5rem;
}
.book-now-btn-container .btn {
  font-size: 2.5rem;
}
.hero {
  position: relative;
  height: 100vh;
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), transparent);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; */
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.hero h2 {
  animation: opening 1s ease-in-out;
}
.hero h3 {
  padding: 5rem;
}
.hero .carousel-text-center {
  position: absolute;
  top: 30%;
}
.carousel {
  height: 100%;
  width: 100%;
}
.carousel-inner {
  height: 100%;
  width: 100%;
}
.carousel-bg {
  position: relative;
  width: 100%;
  height: 100%;
}
.carousel-item {
  height: 100%;
  width: 100%;
}
.carousel-bg .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.9)
  );
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Welcome Section */
.welcome-section {
  padding: 5rem 0;
}
.welcome-section h3 {
  margin-bottom: 3rem;
}
.welcome-section img {
  clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
}
/* package Section */
.package-section {
  padding: 5rem 0;
}
.package-section-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}
.package-card {
  width: 30rem;
  background-color: black;
  color: white;
  padding: 2rem;
  border-radius: 2rem;
  margin-right: 2rem;
}
.package-card .card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.package-section h3 {
  margin-bottom: 3rem;
}
.package-section .btn {
  color: #131c27;
  width: 100%;
  margin-top: 2rem;
  background-color: white;
}
/* About */
#about-us {
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), transparent);
  background-size: cover;
  background-repeat: no-repeat;
}
#about-us .card {
  width: 100%;
  background: none;
  border: none;
  color: white;
}
.about-us-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-us-container .row {
  margin: auto;
  width: 90%;
}
.password-input {
  position: relative;
}
.show-btn {
  position: absolute;
  top: 0;
  margin-top: 0 !important;
  right: 0;
  border-radius: 0 !important;
}
.form-wrapper {
  /* background: rgba(19, 28, 39, 0.8); */
  /* width: 50rem; */
  /* color: white; */
  border-radius: 2rem;
  padding: 2rem;
  font-size: 1.2rem;
}
.form-head {
  text-align: center;
  padding: 4rem;
}
.name-form,
.email-form {
  padding: 3rem;
  text-align: center;
}
/* .form-wrapper label {
  margin: 0rem 3rem;
} */
.form-input-wrapper {
  display: flex;
}
.form-wrapper button {
  padding: 1rem;
  border-radius: 1rem;
  margin-top: 2rem;
  font-size: 1.2rem;
}
.form-wrapper input {
  padding: 1rem 3rem;
}
/* Contact Us Page */

.contact-us-hero {
  min-height: 40vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), transparent);
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  margin-bottom: 10rem;
}
.contact-us-hero h2 {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 175%);
}
.contact-us-container {
  min-height: 60vh;
}
/* About us page */
.about-us-page-hero {
  min-height: 40vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), transparent);
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  margin-bottom: 10rem;
}
.about-us-page-hero h2 {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 175%);
}
.about-us-page-container {
  min-height: 70vh;
}
/* User dashboard */
.booking-card {
  border: 1px solid #dee2e6;
  border-radius: 10px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}
.booking-card .delete-btn {
  background-color: red;
  margin-left: 1rem;
}
.card-image {
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 2rem;
}
.card-image:hover img {
  transform: scale(1.1);
}
.card-image .overlay {
  position: absolute;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.card-title {
  position: absolute;
  bottom: 0;
  padding: 1rem;
}
.card-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: 0.5s ease all;
}
/* Booking */
.booking-page-img {
  height: 40vh;
}
.booking-page-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.book-service-container {
}
.book-service-card {
  border: 1px solid darkgrey;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.book-service-card img {
  height: 2rem;
}
/* Book now modal */
img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.book-modal-now-container .form-control {
  width: 10rem;
}
.book-modal-now-container .left span {
  font-size: 1.25rem;
}
.book-now-modal-img {
}
.react-time-picker__wrapper {
  border: none;
}
.react-time-picker__clear-button {
  display: none;
}
.confirmation-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

/* Profile page */
.profile-page-hero {
  position: relative;
  min-height: 40vh;
  background: linear-gradient(rgba(0, 0, 0, 0.5), transparent);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  text-align: center;
}
.profile-page-hero .welcome-text {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, 175%);
}
.profile-page .nav-tabs {
  border-bottom: none;
}
.profile-page .details .nav-link {
  color: black;
  padding: 1rem 2rem;
  cursor: pointer;
  transition: background 0.5s ease-in-out;
  animation: cloudAnimation 2s forwards;
}
.profile-page .details .nav-link:hover {
  border-radius: 10px;
}
.profile-page .nav-pills .nav-link.active {
  background-color: black;
  border: none;
  border-radius: 10px;
  color: white;
}

.profile-page .no-bookings {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}
/* Footer */

.footer {
  background-color: #131c27;
  color: white;
  padding: 2rem 0;
  /* position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99; */
}
.footer svg {
  height: 20px;
  fill: white;
}
.footer .contact-detail {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.footer .contact-detail p {
  margin: 0 0 0 1rem;
}

/* For hamburger */
/* Navbar base styles */
/* .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  padding: 10px 20px;
}

.logo {
  color: white;
  font-size: 24px;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-links li a {
  color: white;
  text-decoration: none;
  padding: 8px 12px;
}

.nav-links li a:hover {
  background-color: #575757;
  border-radius: 4px;
} */

/* Mobile menu styles */
.mobile-view-logo {
  display: none;
}
.mobile-menu-icon {
  display: none;
  cursor: pointer;
  font-size: 6rem;
  color: white;
  position: absolute;
  right: 5%;
  top: 6%;
  z-index: 99;
}

.mobile-nav-links {
  display: none;
  list-style: none;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #333;
  height: 100%;
  width: 100%;
  /* padding: 10px 0; */
  justify-content: space-around;
  z-index: 9;
  align-items: center;
}

.mobile-nav-links.open {
  display: flex;
}

.mobile-nav-links li a {
  color: white;
  text-decoration: none;
  padding: 10px;
  text-align: center;
}

.mobile-nav-links li a:hover {
  background-color: #575757;
}
@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }
  .mobile-view-logo {
    display: block;
  }
  .mobile-menu-icon {
    display: block; 
    /* top: 0; */
  }
  html {
    font-size: 50%;
  }
  .container {
    width: 85%;
  }
  .package-section-inner {
    flex-direction: column;
  }
  .package-card {
    margin-bottom: 1rem;
  }
}
